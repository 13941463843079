<template>
  <div class="user">
   <div class="block-quote">
      <el-form :inline="true">
        <el-form-item label="用户id" >
          <el-input
            style="width: 180px"
            v-model="uid"
            clearable
            placeholder="请输入用户id"
          ></el-input>
        </el-form-item>
         <el-form-item label="租赁号" >
          <el-input
            style="width: 180px"
            v-model="bid"
            clearable
            placeholder="请输入租赁号"
          ></el-input>
        </el-form-item>
         <el-form-item label="订单号" >
          <el-input
            style="width: 180px"
            v-model="order_no"
            clearable
            placeholder="请输入订单号"
          ></el-input>
        </el-form-item>
         <!-- <el-form-item label="支付价格" >
          <el-input
            style="width: 180px"
            v-model="pay_price"
            clearable
            placeholder="请输入支付价格"
          ></el-input>
        </el-form-item> -->
        <el-form-item>
            <el-date-picker
                v-model="time"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始时间"
                end-placeholder="结束时间">
            </el-date-picker>
        </el-form-item>
        <el-form-item style="float: right">
          <el-button type="primary" @click="search()">搜索</el-button>
        </el-form-item>
      </el-form>
    </div>

    <page-table
      ref="dataTable"
      :data="userList"
      @changeCurrentPage="changeCurrent"
    >
      <el-table-column label="序号" align="center">
        <template slot-scope="scope">
          <span>{{
            (page.currentPage - 1) * page.pageSize + scope.$index + 1
          }}</span>
        </template>
      </el-table-column>

      <el-table-column prop="uid" label="用户ID" align="center">
      </el-table-column>
     <el-table-column prop="bid" label="租赁号" align="center">
      </el-table-column>
      <el-table-column prop="order_no" label="订单号" align="center">
      </el-table-column>
       <el-table-column label="使用收益" align="center">
        <template slot-scope="scope">
          <span>{{scope.row.ads_price}}</span>
          <span >({{scope.row.ads_use}})</span>
        </template>
      </el-table-column>
      <el-table-column prop="pay_price" label="支付价格" align="center">
      </el-table-column>
      <el-table-column prop="created_at" label="创建时间" align="center">
        <template slot-scope="scope">
          {{ scope.row.created_at|formatDate}}
        </template>
      </el-table-column>
    </page-table>
   
  </div>
</template>

<script>
import { serbox} from "@/request/api";
import { checkPermission } from "@/utils/permissions";
import pageTable from "@/components/pageTable.vue";
import { formatDate } from '@/utils/time.js'

export default {
  name: "user",
  components: {
    pageTable
  },
  data() {
    return {
        uid: '',
        bid: '',
        order_no: '',
        pay_price: '',
        userList: [],
      time:[],
      page: {
        //分页信息
        currentPage: 1, //当前页
        pageSize: 10, //每页条数
        total: 0, //总条数
      },
    };
  },
  watch: {
    time(newVal) {
      if (newVal == null) {
        this.time = [];
      }
    },
    },
    filters: {
        formatDate(time) {
            var data = new Date(parseInt(time * 1000));
            return formatDate(data, 'yyyy-MM-dd hh:mm:ss');
        },
    },
  created() {
    this.getUserList(); //获取用户列表
  },
  mounted() {},
  computed: {},
  methods: {
    // add() {
    //   this.$refs.editData.show();
    // },
   
    checkPermission,
    // 切换分页
    changeCurrent(page, size) {
      this.page.currentPage = page;
      this.page.pageSize = size;
      this.getUserList();
    },

    getUserList() {
      let token = sessionStorage.getItem("token");
      this.token = token;
        let params = {
        token: this.token,
        page:this.page.currentPage,
        limit: this.page.pageSize,
        uid: this.uid,
            bid: this.bid,
        // pay_price:this.pay_price,
        order_no: this.order_no,
        time1: this.time[0],
        time2:this.time[1]
        }
      console.log(this.time,params)
       serbox(params).then((res) => {
        // console.log(res,'11111111')
        this.userList = res.data.data
        this.page.total=res.data.count
        this.$refs.dataTable.setPageInfo({
          total: this.page.total,
        });
      })
    },
    search() {
      console.log('搜索')
      this.page.currentPage = 1
      this.getUserList()
      },
    
  },
};
</script>

<style lang="scss" scoped>
</style>
